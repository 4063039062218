import React from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/sidebar/Sidebar";
import { useWindowSize } from "../../helpers/useWindowSize";
import { Cards } from "./Cards/Cards";
import { mentors } from "./mentorsTEST.const";
import { Link } from "react-router-dom";
import { routes } from "../../routes/routes";
import { MentorsLayout } from "./MentorsLayout";

export const Mentors = () => {
  const { width: screenWidth } = useWindowSize();
  // const showModal = false;

  return (
    <MentorsLayout>
      <div className={`${screenWidth >= 950 ? "mt-[50px]" : "mt-0" } row-start-2 col-start-1 col-end-13 row-end-12 overflow-x-auto relative ${screenWidth <= 950 ? (screenWidth <= 550 ? 'grid grid-cols-1' : 'grid grid-cols-2') : ''}`}>
        {mentors.map((mentor, index) => (
          <Cards
            key={index}
            mentorData={mentor}
            index={index}
            screenWidth={screenWidth}
          />
        ))}
        </div>
    </MentorsLayout>
  );
};
