import { Discriptions } from "./Bottom/Discriptions"
import { Experience } from "./Bottom/Experience"
import { Projects } from "./Bottom/Projects"

export const DetailInfoBottom = ({ mentorData, screenWidth }) => {
  return (
    <div className={'w-full flex mt-[50px]'}>
      <div className={'w-[75vw] mx-auto flex flex-col text-dark font-semibold text-[14px] leading-[18.2px]'}>
        <Discriptions mentorData={mentorData} />
        <Projects mentorData={mentorData}/>
        <Experience mentorData={mentorData}/>
        <div className={`mt-[50px] mb-[150px] flex flex-wrap justify-center items-center ${screenWidth <= 1000 ? 'flex-col' : ``}`}>
          <button className={`h-[44px] ${screenWidth <= 1000 ? 'mb-[25px] w-[100%]' : `mr-[20px] w-[15%]`} bg-buttonblue text-white border border-buttonblue rounded-[10px]`}>
            Оплатить
          </button>
          <button className={`${screenWidth <= 1000 ? 'w-[100%]' : `w-[15%]`} h-[44px] text-buttonblue border border-buttonblue rounded-[10px]`}>
            Оставить заявку
          </button>
        </div>
      </div>
    </div>
  )
}
