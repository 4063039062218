export const Experience = ({ mentorData }) => {
  return (
    <>
      <p className="mt-[40px] mb-[50px]">Опыт работы:</p>
      {mentorData.experience.map((exp, index) => (
        <div key={index} className="mb-[50px] flex">
          <div className="w-[20%]">
            <img src={exp.img} alt={exp.company} className="w-[100px]"/>
          </div>
          <div className="w-[80%]">
            <div className="">{exp.position}</div>
            <div className="font-semibold">{exp.company}</div>
            <div className="text-gray mt-[20px] mb-[10px]">{exp.date}</div>
            <ul className="list-disc pl-[20px]">
              {exp.skills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};
