import React, { useState } from 'react';
import { dateFormat } from '../../helpers/dateFormat';
import logo from '../../assets/footprint-light.png';
import { formatSalary } from '../../helpers/salaryUtils';

function VacanciesList({ jobs, onClickVacancy, chosenJob, handleBookmarkClick, bookmarked }) {

  const [showDescription, setShowDescription] = useState(false);
  
  const handleMouseEnter = () => {
    setShowDescription(true);
  };
  const handleMouseLeave = () => {
    setShowDescription(false);
  };

  return (
    <>
      {jobs.map((job, index) => (
        <div className="flex flex-col gap-y-3 w-full items-center" key={index}>
          <div
            style={{ height: 'auto' }}
            className={`${chosenJob.id === job.id ? 'bg-[#BFD4FF] bg-opacity-10 border-l-[15px] mb-3 border-opacity-40 border-buttonblue font-semibold' : 'pl-3'} text-gray cursor-pointer rounded-lg text-sm ml-3 hover:bg-blue-50 pt-3 pb-5 pr-7 w-full content-between text-darkSecondary font-normal h-[160px] xl:w-[355px] xl:h-[160px] xl:p-4 sm:border-0 sm:ml-0 xl:pt-2`}
            onClick={() => onClickVacancy(job, job.id)}
          >
            <div className={`text-end w-full mb-2 text-[12px] ${chosenJob.id === job.id && 'text-gray'}`}>{job.time_of_public ? dateFormat(job.time_of_public) : ''}</div>
            <div className="flex gap-x-5 justify-evenly">
              <div className="w-[15%] flex flex-col items-center self-center p-2 xl:w-[56px]">
                {job.logo ? (
                  <div className='w-[40px] mb-5'> <img src={job.logo} alt={job.company} className="w-full"/></div>
                ) : (
                  <div className='w-[40px] mb-5'> <img src={logo} alt={job.company} className="w-full"/></div>
                )}
              </div>
              <div className="w-[60%] flex flex-col justify-between xl:w-[250px]">
                <div className="flex justify-between">
                  <div className="">
                    <h3 className={`${chosenJob.id === job.id ? 'text-buttonblue' : ''} mb-[12px] text-[14px] line-clamp-2 text-ellipsis text-buttonblue w-[100%] xl:w-[205px]`}>{job.vacancy}</h3>
                    <p className={`${chosenJob.id === job.id ? ' text-buttonblue' : ''}mb-[12px] text-[12px] truncate`}>{job.level}</p>
                  </div>
                  {/* <div> <img src={bookmark} className={`${bookmarked.includes(job.id) ? 'opacity-100' : 'opacity-30'} hover:opacity-100 transition-all`} alt="bookmark" onClick={() => handleBookmarkClick(job.id)}/></div> */}
                </div>
                <div className="mb-[12px] flex justify-between gap-x-6">
                  <div className="w-[65%]">
                    <p className={`${chosenJob.id === job.id ? 'text-black font-semubold ' : ''}text-[12px] mb-[12px] truncate text-dark`}>
                      {formatSalary(job.salary)}
                    </p>
                    <p className={`text-[12px] truncate ${chosenJob.id === job.id && 'text-gray'}`}>{job.city}</p>
                  </div>
                  <div className="w-[25%] text-[12px]">
                    <p className={`text-right truncate text-[12px] ${chosenJob.id === job.id ? 'hidden' : 'block'}`}>
                      {job.typeOfEmployment}
                    </p>
                    <p className={`text-right truncate text-[12px] ${chosenJob.id === job.id ? 'hidden' : 'block'}`}>
                    </p>
                  </div>
                </div>
                <div className='sm:flex flex mb-[12px] colum relative'
                  onMouseEnter={() => handleMouseEnter(job.description)}
                  onMouseLeave={handleMouseLeave}>
                    <p className={`${chosenJob.id === job.id ? 'text-black font-medium' : ''}sm:w-[70%] w-[90%] text-[12px] uppercase line-clamp-2 text-ellipsis break-words`}>{job.company}</p>
                    <span className="flex items-center gap-1 w-[10%] sm:w-[30%]">
                    {job.job_type.includes('remote') ? (
                      <span
                        className='text-[12px] text-buttonblue'
                      >
                        R
                      </span>
                    ) : (
                      <span></span>
                    )}
                    {showDescription && (
                      <div className="text-[12px] absolute bg-white">
                        {job.job_type}
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[334px] mx-auto border-t border-divideGray self-end xl:w-[280px] xl:self-center"></div>
        </div>
      ))}
    </>
  )
}

export default VacanciesList;
