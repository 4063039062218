import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { CradsContent } from "./CradsContent/CradsContent";
import { Rating } from '../../../common/Rating/Rating';
import { Link } from "react-router-dom";
export const Cards = ({ mentorData, index, screenWidth }) => {
  const marginTop = index !== 0 ? 'mt-[45px]' : 'mt-0';

  return (
    <div className={`flex ${screenWidth <= 950 ? 'mt-[45px]' : `${marginTop} w-full`} items-center justify-center`}>
      <div className={`flex-grow ${screenWidth <= 1250 ? 'mx-[35px] justify-center' : 'mx-[250px] justify-between'} pb-[45px] relative flex `}>
        {screenWidth > 950 ? (
          <>
            <div>
              <img src={mentorData.photo} alt="" />
              <Rating rating={mentorData.rating} />
              <Link to={`#`} className="text-buttonblue block text-center mt-[10px]">
                {mentorData.reviewsCount} Отзывов
              </Link>
            </div>
            <div className="w-4/5 px-[50px] flex flex-col">
            <CradsContent
                data={mentorData} 
                screenWidth={screenWidth}
            />
            </div>
            <div className="w-10%">
              <FontAwesomeIcon icon={faBookmark} className="text-skyblue w-[17px] h-[26px]" />
            </div>
            <div className="w-full h-[2px] bg-divideGray absolute left-1/2 transform -translate-x-1/2 bottom-0" style={{ width: "70%" }}></div>
          </>
        ) : (
          <>
            <div className="relative">
              <img className="mt-[10px]" src={mentorData.photo} alt="" />
              <div className="absolute left-[5px] top-[15px]">
                <FontAwesomeIcon icon={faBookmark} className="text-skyblue w-[17px] h-[26px] mx-[auto]" />
              </div>
              <CradsContent
                data={mentorData} 
                screenWidth={screenWidth}
              />
              <Rating rating={mentorData.rating} className="mt-[10px]" />
              <Link to={`#`} className="text-buttonblue block text-center">
                {mentorData.reviewsCount} Отзывов
              </Link>
            </div>
            <div className="w-full h-[2px] bg-divideGray absolute left-1/2 transform -translate-x-1/2 bottom-0"></div>
          </>
        )}
      </div>
    </div>
  );
};
