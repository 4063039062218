import mentorsImg from "../../assets/mentorsImg.png";

export const mentors = [
  {
    name: 'Егоров Василий',
    id: 1,
    photo: mentorsImg,
    rating: '3',
    reviewsCount: '10',
    filters: ["php", "Python", "Backend", "Базы данных", "Архитектура", "Frontend"],
    mentor: 
      {name: "Савицкий Илья",
        link: "https://www.youtube.com/"
      },
    position: "Software Developer, Wargaming",
    text: "Занимаюсь коммерческой разработкой на PHP более 6 лет...Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac facilisis nisi, non lacinia metus. Vivamus eu urna ut libero tempor dignissim. Vestibulum ac venenatis dolor. Fusce tristique, dui eu interdum gravida, velit orci rhoncus metus, vel ultrices orci eros id est. Proin nec elementum neque. Nulla facilisi. Etiam sed suscipit tellus, ut iaculis nunc.Cras eu elit eu purus efficitur pharetra a eu erat. Curabitur feugiat nisi non varius finibus. Maecenas vel sagittis urna. Aenean vel justo id libero interdum luctus vel in libero. Nullam at auctor mauris. Pellentesque suscipit ligula nunc, in viverra lectus ullamcorper non. Vivamus vel nisi tincidunt, euismod ante ut, tristique odio.",
    location: "Минск, BY",
    languages: ["English", "Русский"],
    price: "20$ / час",
    promotions: "1 занятие бесплатно",
  },
  {
    name: 'Егоров Василий',
    id: 2,
    photo: mentorsImg,
    rating: '2',
    reviewsCount: '10',
    filters: ["php", "Python", "Backend", "Базы данных", "Архитектура", "Frontend"],
    mentor: 
      {name: "Савицкий Илья",
        link: "https://www.youtube.com/"
      },
    position: "Software Developer, Wargaming",
    text: "Занимаюсь коммерческой разработкой на PHP более 6 лет...Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac facilisis nisi, non lacinia metus. Vivamus eu urna ut libero tempor dignissim. Vestibulum ac venenatis dolor. Fusce tristique, dui eu interdum gravida, velit orci rhoncus metus, vel ultrices orci eros id est. Proin nec elementum neque. Nulla facilisi. Etiam sed suscipit tellus, ut iaculis nunc.Cras eu elit eu purus efficitur pharetra a eu erat. Curabitur feugiat nisi non varius finibus. Maecenas vel sagittis urna. Aenean vel justo id libero interdum luctus vel in libero. Nullam at auctor mauris. Pellentesque suscipit ligula nunc, in viverra lectus ullamcorper non. Vivamus vel nisi tincidunt, euismod ante ut, tristique odio.",
    location: "Минск, BY",
    languages: ["English", "Русский"],
    price: "20$ / час",
    promotions: "1 занятие бесплатно",
  },
  {
    name: 'Егоров Василий',
    id: 3,
    photo: mentorsImg,
    rating: '2',
    reviewsCount: '10',
    filters: ["php", "Python", "Backend", "Базы данных", "Архитектура", "Frontend"],
    mentor: 
      {name: "Савицкий Илья",
        link: "https://www.youtube.com/"
      },
    position: "Software Developer, Wargaming",
    text: "Занимаюсь коммерческой разработкой на PHP более 6 лет...Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac facilisis nisi, non lacinia metus. Vivamus eu urna ut libero tempor dignissim. Vestibulum ac venenatis dolor. Fusce tristique, dui eu interdum gravida, velit orci rhoncus metus, vel ultrices orci eros id est. Proin nec elementum neque. Nulla facilisi. Etiam sed suscipit tellus, ut iaculis nunc.Cras eu elit eu purus efficitur pharetra a eu erat. Curabitur feugiat nisi non varius finibus. Maecenas vel sagittis urna. Aenean vel justo id libero interdum luctus vel in libero. Nullam at auctor mauris. Pellentesque suscipit ligula nunc, in viverra lectus ullamcorper non. Vivamus vel nisi tincidunt, euismod ante ut, tristique odio.",
    location: "Минск, BY",
    languages: ["English", "Русский"],
    price: "20$ / час",
    promotions: "1 занятие бесплатно",
  },
  {
    name: 'Егоров Василий',
    id: 4,
    photo: mentorsImg,
    rating: '2',
    reviewsCount: '10',
    filters: ["php", "Python", "Backend", "Базы данных", "Архитектура", "Frontend"],
    mentor: 
      {name: "Савицкий Илья",
        link: "https://www.youtube.com/"
      },
    position: "Software Developer, Wargaming",
    text: "Занимаюсь коммерческой разработкой на PHP более 6 лет...Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac facilisis nisi, non lacinia metus. Vivamus eu urna ut libero tempor dignissim. Vestibulum ac venenatis dolor. Fusce tristique, dui eu interdum gravida, velit orci rhoncus metus, vel ultrices orci eros id est. Proin nec elementum neque. Nulla facilisi. Etiam sed suscipit tellus, ut iaculis nunc.Cras eu elit eu purus efficitur pharetra a eu erat. Curabitur feugiat nisi non varius finibus. Maecenas vel sagittis urna. Aenean vel justo id libero interdum luctus vel in libero. Nullam at auctor mauris. Pellentesque suscipit ligula nunc, in viverra lectus ullamcorper non. Vivamus vel nisi tincidunt, euismod ante ut, tristique odio.",
    location: "Минск, BY",
    languages: ["English", "Русский"],
    price: "20$ / час",
    promotions: "1 занятие бесплатно",
  },
  {
    name: 'Егоров Василий',
    id: 5,
    photo: mentorsImg,
    rating: '2',
    reviewsCount: '10',
    filters: ["php", "Python", "Backend", "Базы данных", "Архитектура", "Frontend"],
    mentor: 
      {name: "Савицкий Илья",
        link: "https://www.youtube.com/"
      },
    position: "Software Developer, Wargaming",
    text: "Занимаюсь коммерческой разработкой на PHP более 6 лет...Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac facilisis nisi, non lacinia metus. Vivamus eu urna ut libero tempor dignissim. Vestibulum ac venenatis dolor. Fusce tristique, dui eu interdum gravida, velit orci rhoncus metus, vel ultrices orci eros id est. Proin nec elementum neque. Nulla facilisi. Etiam sed suscipit tellus, ut iaculis nunc.Cras eu elit eu purus efficitur pharetra a eu erat. Curabitur feugiat nisi non varius finibus. Maecenas vel sagittis urna. Aenean vel justo id libero interdum luctus vel in libero. Nullam at auctor mauris. Pellentesque suscipit ligula nunc, in viverra lectus ullamcorper non. Vivamus vel nisi tincidunt, euismod ante ut, tristique odio.",
    location: "Минск, BY",
    languages: ["English", "Русский"],
    price: "20$ / час",
    promotions: "1 занятие бесплатно",
  }
];

export const mentorsDetail = {
  name: 'Егоров Василий',
  id: 1,
  photo: mentorsImg,
  rating: '3',
  reviewsCount: '10',
  filters: ["php", "Python", "Backend", "Базы данных", "Архитектура", "Frontend"],
  mentor: 
    {name: "Савицкий Илья",
      link: "https://www.youtube.com/"
    },
  position: "Software Developer, Wargaming",
  text: "Занимаюсь коммерческой разработкой на PHP более 6 лет...Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac facilisis nisi, non lacinia metus. Vivamus eu urna ut libero tempor dignissim. Vestibulum ac venenatis dolor. Fusce tristique, dui eu interdum gravida, velit orci rhoncus metus, vel ultrices orci eros id est. Proin nec elementum neque. Nulla facilisi. Etiam sed suscipit tellus, ut iaculis nunc.Cras eu elit eu purus efficitur pharetra a eu erat. Curabitur feugiat nisi non varius finibus. Maecenas vel sagittis urna. Aenean vel justo id libero interdum luctus vel in libero. Nullam at auctor mauris. Pellentesque suscipit ligula nunc, in viverra lectus ullamcorper non. Vivamus vel nisi tincidunt, euismod ante ut, tristique odio.",
  location: "Минск, BY",
  languages: ["English", "Русский"],
  price: "20$ / час",
  promotions: "1 занятие бесплатно",
  skills: [
    "написание авто тестов для BackEnd;",
    "использование WebSocket и ZeroMQ;",
    "работа с базой данных SQLite и DBeaver;",
    "создание и подключение npm модулей (recaptche);",
    "разработка модуля для работы по протоколу ldap;",
    "сборка проекта для установки на сервере в среде Windows.",
    "сделал конвертер из ini файлов одной программы в json другой (Pkg);",
    "переход с webpack третьей версии на четвёртую;",
    "работа с git;",
    "использование post/get запросов в работе;",
    "описание типов в проекте для TypeScript (в т.ч. NodeJS);",
    "создание нового функционала и новых компонент в библиотеке React.JS;",
    "изменение компонент React.JS с классов на хуки;"
  ],
  projects: [
    {
      link: 'https://www.google.com/search?q=Hostaway&oq=Hostaway&aqs=chrome..69i57j0i512l9.697j0j9&sourceid=chrome&ie=UTF-8',
      name: 'Hostaway',
      text: 'Взаимодействие со множеством каналов по API и синхронизация данных в БД'
    },
    {
      link: 'https://www.google.com/search?q=%D0%A1%D0%9E%D0%92%D0%90&oq=%D0%A1%D0%9E%D0%92%D0%90&aqs=chrome..69i57j0i512l8j0i271.2627j0j7&sourceid=chrome&ie=UTF-8',
      name: 'СОВА',
      text: 'Создание приложения “СОВА” – учетная система, являющаяся альтернативой кассовым аппаратам. Установка, настройка сервера приложений. Запуск в прод.'
    }
  ],
  experience: [
    {
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/I.T_logo.svg/1030px-I.T_logo.svg.png',
    company: 'Exadel',
    position: 'Senior Python Developer',
    date: 'Август 2019 - наст. время',
    skills: [
      "написание авто тестов для BackEnd;",
      "использование WebSocket и ZeroMQ;",
      "работа с базой данных SQLite и DBeaver;",
      "создание и подключение npm модулей (recaptche);",
      "разработка модуля для работы по протоколу ldap;",
      "сборка проекта для установки на сервере в среде Windows.",
      "сделал конвертер из ini файлов одной программы в json другой (Pkg);",
      "переход с webpack третьей версии на четвёртую;",
      "работа с git;",
      "использование post/get запросов в работе;",
      "описание типов в проекте для TypeScript (в т.ч. NodeJS);",
      "создание нового функционала и новых компонент в библиотеке React.JS;",
      "изменение компонент React.JS с классов на хуки;"
    ]
  },
  {
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/I.T_logo.svg/1030px-I.T_logo.svg.png',
    company: 'Exadel',
    position: 'Senior Python Developer',
    date: 'Август 2019 - наст. время',
    skills: [
      "написание авто тестов для BackEnd;",
      "использование WebSocket и ZeroMQ;",
      "работа с базой данных SQLite и DBeaver;",
      "создание и подключение npm модулей (recaptche);",
      "разработка модуля для работы по протоколу ldap;",
      "сборка проекта для установки на сервере в среде Windows.",
      "сделал конвертер из ini файлов одной программы в json другой (Pkg);",
      "переход с webpack третьей версии на четвёртую;",
      "работа с git;",
      "использование post/get запросов в работе;",
      "описание типов в проекте для TypeScript (в т.ч. NodeJS);",
      "создание нового функционала и новых компонент в библиотеке React.JS;",
      "изменение компонент React.JS с классов на хуки;"
    ]
  }
  ]
}
