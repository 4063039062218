import React from "react";
import "./сardsInfo.css";
import { Link } from "react-router-dom";

export const CardsInfo = ({ dataMentor, screenWidth }) => {
  return (
    <>
      {screenWidth > 950 ? (
        <div>
          <a className="text-buttonblue" href={dataMentor.link}>
            {dataMentor.name}
          </a>
          <div className="cursor-default mt-[8px]">{dataMentor.position}</div>
          <p className="cursor-default relative mt-[26px] mb-[20px] max-h-[55px] overflow-hidden custom-text-blur">
            {dataMentor.text}
          </p>
          <div className="flex flex-wrap font-semibold text-gray text-sm justify-between mb-[26px]">
            <span className={'cursor-default mr-[10px] mt-[5px]'}>{dataMentor.location}</span>
            <span className={'cursor-default mr-[10px] mt-[5px]'}>{dataMentor.languages.join(', ')}</span>
            <span className={'cursor-default mr-[10px] mt-[5px]'}>{dataMentor.price}</span>
            <span className={'cursor-default mr-[10px] mt-[5px]'}>{dataMentor.promotions}</span>
          </div>
          <Link className="text-buttonblue" to={`/mentors/${dataMentor.id}`}>
            Подробнее
          </Link>
        </div>
      ) : (
        <div className="flex flex-col text-center mt-[10px]">
          <a className="text-buttonblue" href={dataMentor.link}>
            {dataMentor.name}
          </a>
          <Link className="text-buttonblue" to={`/mentors/${dataMentor.id}`}>
            Подробнее
          </Link>
        </div>
      )}
    </>
  );
};
