import { useState } from 'react';

export const useRatingButton = (initialRating) => {
  const [hoveredRating, setHoveredRating] = useState(initialRating);
  const [selectedRating, setSelectedRating] = useState(initialRating);

  const handleMouseEnter = (hoveredRating) => {
    setHoveredRating(hoveredRating);
  };

  const handleMouseLeave = () => {
    setHoveredRating(selectedRating);
  };

  const handleClick = (clickedRating) => {
    setSelectedRating(clickedRating);
  };

  return {
    hoveredRating,
    selectedRating,
    handleMouseEnter,
    handleMouseLeave,
    handleClick,
  };
};
