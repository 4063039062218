export const Discriptions = ({ mentorData }) => {
  return (
    <>
      <p className=" mb-[40px]">
        {mentorData.text}
      </p>
      <p className=" mb-[20px]">Умения и навыки:</p>
      <ul className="list-disc pl-[20px] ">
      {mentorData.skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
    </>
  )
}
