import { CardsFilter } from "./CardsFilter/CardsFilter"
import { CardsInfo } from "./CardsInfo/CardsInfo"

export const CradsContent = ({ data, screenWidth }) => {
  return(
    <>
      <CardsFilter
        screenWidth={screenWidth}
        filters={ data.filters }
      />
      <CardsInfo
        screenWidth={screenWidth}
        dataMentor={data}
      />
    </>
  )
}
