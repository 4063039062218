import { DetailInfoTop } from './DetailInfo/DetailInfoTop';
import { DetailInfoMiddle } from './DetailInfo/DetailInfoMiddle.jsx';
import { DetailInfoBottom } from './DetailInfo/DetailInfoBottom';
import { useWindowSize } from '../../../helpers/useWindowSize';

export const CardsDetail = ({ id, mentorData }) => {
  const { width: screenWidth } = useWindowSize();

  return (
    <>
      <DetailInfoTop 
      mentorData={mentorData}
      />

      <DetailInfoMiddle
      mentorData={mentorData}
      />

      <DetailInfoBottom
      mentorData={mentorData}
      screenWidth={screenWidth}
      />
    </>
  );
}
