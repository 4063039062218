import { faGlobe, faLocationDot, faSuitcase } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const DetailInfoMiddle = ({ mentorData }) => {
  return (
    <div className={'w-full flex mt-[30px]'}>
    <div className={'w-[75vw] mx-auto flex justify-between mb-[40px] pb-[40px] border-b-2 border-skyblue'}>
      <div className={'flex flex-col w-[25%]'}>
        <div className={'flex'}>
          <div className={'w-[15px]'}><FontAwesomeIcon style={{ color: '#4985FF' }} icon={faSuitcase} /></div>
          <span className="mb-[10px] ml-[10px] cursor-default text-darkSecondary">{mentorData.position}</span>
        </div>
        <div className={'flex'}>
          <div className={'w-[15px]'}><FontAwesomeIcon style={{ color: '#4985FF' }} icon={faLocationDot} /></div>
          <span className="mb-[10px] ml-[10px] cursor-default text-darkSecondary">{mentorData.location}</span>
        </div>
        <div className={'flex'}>
          <div className={'w-[15px]'}><FontAwesomeIcon style={{ color: '#4985FF' }} icon={faGlobe} /></div>
          <span className="ml-[10px] cursor-default text-darkSecondary">{mentorData.languages.join(', ')}</span>
        </div>
      </div>
      <div className='w-[25%] flex flex-col'>
        <button className='h-[44px] mb-[10px] bg-buttonblue text-white border border-buttonblue rounded-[10px]'>
          Оплатить
        </button>
        <button className='h-[44px] text-buttonblue border border-buttonblue rounded-[10px]'>
          Оставить заявку
        </button>
      </div>
    </div>
  </div>
  )
}
