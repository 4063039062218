export const CardsFilter = ({ filters, classN, screenWidth }) => {
  return (
    <>
      {screenWidth > 950 ? (
        <div className={`mb-[20px] ${classN}`}>
          {filters.map((filter, index) => (
            <button
              key={index}
              className={`border max-h-[85px] border-gray-300 px-[10px] py-[5px] rounded-[15px] mr-[10px] mb-[10px]`}
            >
              {filter}
            </button>
          ))}
        </div>
      ) : null}
    </>
  );
};
