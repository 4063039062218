import { Link } from "react-router-dom";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/sidebar/Sidebar";
import logo from '../../assets/logohead.svg'


export const MentorsLayout = ({ children }) => {
  return (
    <div className={`grid grid-cols-12 grid-rows-12 h-screen gap-0 font-semibold text-sm text-gray sm:grid-rows-12-mobile overflow-hidden`}>
      <Sidebar />
      <div className={`grid grid-cols-11 grid-rows-11 row-start-1 col-start-2 col-end-13 row-end-13 relative gap-0 sm:col-start-1 sm:grid-rows-11-mobile`}>
        <div className="h-[63px] row-start-1 row-end-1 col-start-1 col-end-13 flex justify-between shadow-bottom sm:block sm:col-start-1 sm:px-4 sm:row-end-1 sm:shadow-none">
        <Link className="hidden w-[63px] h-[63px] sm:flex sm:justify-center sm:items-center mx-5 gap-x-8 sm:w-full sm:mx-0 bg-white" to="/">
            <img src={logo} alt="Логотип" />
        </Link>
        </div>
          {children}
      </div>
    </div>
  );
};
