import { useNavigate } from "react-router";
import { Rating } from "../../Cards/Rating/Rating";
import { Link } from "react-router-dom";
import { CardsFilter } from "../../Cards/CradsContent/CardsFilter/CardsFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const DetailInfoTop = ({ mentorData }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <button className={'mx-[50px] fixed'} onClick={goBack}>
        <FontAwesomeIcon
          style={{ width: '50px', height: '50px', color: '#4985FF' }}
          icon={faArrowLeft}
        />
      </button>
      <div className={`w-[75vw] mx-auto flex items-center justify-center overflow-auto`}>
        <div className={`flex flex-col relative`}>
          <div>
            <img src={mentorData.photo} alt="" />
            <div className={'flex pb-[22px] border-b-2 border-skyblue mb-[22px]'}>
              <Rating rating={mentorData.rating} />
              <Link to={`#`} className="text-buttonblue block text-center mt-[20px] w-full">
                {mentorData.reviewsCount} Отзывов
              </Link>
            </div>
            <div className={'flex flex-col'}>
              <span className={'text-center mb-[10px]'}>{mentorData.name}</span>
              <span className={'text-center'}>{mentorData.price}</span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ maxWidth: '50vw', margin: '30px auto 0 auto' }} className={'flex items-center justify-center mt-[30px]'}>
        <CardsFilter classN={'flex flex-wrap items-center justify-center'} filters={mentorData.filters} />
      </div>
    </>
  );
}
