import { useParams } from "react-router";
import { MentorsLayout } from "./MentorsLayout";
import { mentorsDetail } from "./mentorsTEST.const";
import { CardsDetail } from "./CardsDetail/CardsDetail";

export const MentorDetail = () => {
  // const { width: screenWidth } = useWindowSize();
  // const showModal = false;

  const { id } = useParams();

  return (
    <MentorsLayout>
      <div className={`row-start-2 mt-[45px] sm:mt-[25px] col-start-1 col-end-13 row-end-12 overflow-x-auto relative`}>
        <CardsDetail
        id={id}
        mentorData={mentorsDetail}
        />
      </div>
    </MentorsLayout>
  );
}
