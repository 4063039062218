import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './rating.css'
import { useRatingButton } from './useRatingButton';

export const Rating = ({ rating, className="" }) => {
  const {
    hoveredRating,
    selectedRating,
    handleMouseEnter,
    handleMouseLeave,
    handleClick,
  } = useRatingButton(rating);

  const stars = Array.from({ length: 5 }, (_, index) => {
    const starRating = index + 1;
    const isFilled = starRating <= (hoveredRating || selectedRating);

    return (
      <button
        key={index}
        onMouseEnter={() => handleMouseEnter(starRating)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(starRating)}
        className={`star ${isFilled ? 'goldStar' : ''}`}
      >
        <FontAwesomeIcon icon={faStar} />
      </button>
    );
  });

  return (
    <div className={`w-full flex justify-center items-center mt-[20px] ${className}`}>
      <div className="mr-[10px] font-Raleway font-semibold text-600 text-[14px] leading-[18.2px]">
        {rating}
      </div>
      <div className="flex">
        {stars}
      </div>
    </div>
  );
};
