export const Projects = ({ mentorData }) => {
  return (
    <>
      <p className="mt-[40px]">Проекты:</p>
      <div className="mt-[20px]">
        {mentorData.projects.map((project, index) => (
          <div key={index} className="mb-[20px] flex justify-between">
            <a href={project.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 font-semibold w-[20%] flex items-center justify-center">
              {project.name}
            </a>
            <span className="ml-2 text-dark w-[70%]">{project.text}</span>
          </div>
        ))}
      </div>
    </>
  );
};
